.card {
	background-color: white;
	color: #272727;
	padding: 10px;
	border-radius: 10px;
	border: 0px;
	border-color: tomato;
	border-style: solid;
	transition: 200ms;
    max-width: 1000px;
	
}

.card:hover {
	transition: 200ms;
	border: 1px;
	border-color: tomato;
	border-style: solid;
	border-width: thin;
}

.card-header {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

}

.profile {
	width: 3.5rem;
	height: 2.5rem;
	background-color: tomato;
	border-radius: 50%;
	margin: 5px;
	font-size: 20pt;
	text-align: center;
	font-weight: bold;
	color: white;   
	justify-content: center;
}

.letter {
	vertical-align: middle;
}

.card-title-group {
	justify-content: center;
}
.lowpercent {
	color: red;
	font-size: 20px;
}
.lowpercentmap {
	color: red;
	font-size: 16px;
}
.highpercent {
	color: green;
	font-size: 20px;
}
.highpercentmap {
	color: green;
	font-size: 16px;
}
.card-title {
	flex: 0.5;
	font-size: medium   !important;
	margin-bottom: 0;
	margin-top: 0;
}
.card-date {
	flex: 0.3;	
	font-size: small;
	margin-top: 0;
}

.card-image {
	border-radius: 10px;
	margin-bottom: 10px;
	margin-top: 10px;
}
.card-like-icon {
	height: 25px;
}

.card-text {
	display: flex;
  grid-column: 1;
  justify-content: start;
	font-size: medium;
}

.card-like-bar {
	display: flex;
	align-items: center;
	margin-top: 20px;
	justify-content: space-between;
}

.like-text {
	font-size: small;
	margin-left: 10px;
}
.body {
  background-color: #6c7177; /* Gray background color */
}
.app {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
}
.header1 {
  text-align: center;
}

.header h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}
.header1  {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.sub-header {
  background-color: #6f8e95;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 30px; /* Rounded edges */
}

.sub-header h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  border-radius: 30px; /* Rounded edges */
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.left-column {
  flex: 1;
  padding-right: 20px;
}

.right-column {
  flex: 1;
  padding-left: 20px;
}

.list {
  list-style-type: none;
  padding-left: 0;
}

.list li {
  font-size: 18px;
  margin-bottom: 10px;
}

.button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 20px;
}

.button:hover {
  background-color: #0056b3;
}
.video-container {
  position: relative;
  width: 100%;
  padding-top: calc(9 / 16 * 100%);
  /* 16:9 aspect ratio */
  overflow: hidden;
}

.program-description {
  font-size: 25px;
}
.limited-space {
  color: red;
  font-size: 33px;
  font-weight: bold;
}
.video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 100%;
  border: 2px solid #6f8e95; /* Blue border */
  border-radius: 20px; /* Rounded corners */
}
.email-link {
  font-size: 18px;
  color: #007bff; /* Blue color for consistency */
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}
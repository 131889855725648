.modal-90w { 
    max-width: 60% !important; 
    max-height:  none;

}
.modal-100w { 
    max-width: 100% !important; 
    max-height:  none;

}
.lowpercent {
	color: red;
    font-size: 20px;
 
}
.highpercent {
	color: green;
    font-size: 20px;

}
.slider-container {
    width: 100%;
    height: 200;
    /* position:fixed;  */
    /* add this code for position fixed */
    top:0px; /* set top and left 0 */
    left:0px;
}
.slider-item-div {
    padding: 20px;
    background-color: white;
    text-align: center;
    height: 200;
    width: 100%;
}
.carousel-style {
    height: 200 !important;
}


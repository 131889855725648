/* src/components/FormContainer.css */
.form-container {
    display: flex;
    flex-direction: column;       /* Stack items vertically */
    justify-content: center;      /* Center items horizontally */
    align-items: center;          /* Center items vertically */
    height: 100vh;                /* Full viewport height */
    background-color: #f0f0f0;    /* Light grey background */
  }
  td {
    text-align: left;
  }
  .form-container input,
  .form-container button {
    width: 80%;                   /* 80% of the container's width */
    margin: 10px 0;               /* 10px vertical margin */
    padding: 10px;                /* Padding inside the inputs and button */
    border-radius: 5px;           /* Rounded corners */
    border: 1px solid #ccc;       /* Light grey border */
  }
  
  .form-container button {
    background-color: #4CAF50;    /* Green background for the button */
    color: white;                 /* White text for the button */
    cursor: pointer;              /* Pointer cursor on hover */
  }
  
.container {
    display: flex;
    height: 100vh; /* Set the container height to 100% of the viewport height */
  }
  
  .column1,
  .column2 {
    display: flex;
    flex-direction: column; /* Make columns behave like flex columns */
  }
  
  .column1 {
    flex: 0 0 60%;
    
  }
  
  .column2 {
    flex: 0 0 40%;
  }
  
  .row {
    padding: 10px;
    
    position: relative; /* Ensure relative positioning for child flexbox */
  }
  
  .row:last-child {
    border-bottom: none; /* Remove border from the last row */
  }
  
  .row:nth-child(1) {
    height: 50%; /* Set the height of the first row in column 1 to 60% */
  }
  
  .column2 .row {
    flex: 1; /* Make each row in column 2 take up equal height */
  }
  
  .flexbox {
    display: flex;
    width: 70%;
     
    height: 100%; /* Set the height of the flexbox to 100% */
  }
  .carousel .thumb img {
    width: 40% !important;
    height: 40% !important;
}

.carousel .slide img {
    max-height: 400px;  /* change this to whatever you want */
    width: 500px;
}
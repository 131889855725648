.modal-dialog {
    height: 100%;
    width:100%;
  }
.modal-content {
/* 80% of window height */
height: 100%;
width:100%;
background-color:#e7f1f2;
}        
.modal-header {
background-color: #99abad;
padding:16px 16px;
color:#FFF;
border-bottom:2px dashed #99abad;
}
body.modal-open {
  overflow: hidden;
  position: fixed;
}

.mybutton {
  border-radius: 1px  !important;
  padding: 2px 2px 2px 2px  !important;
  margin: 2px 2px 2px 2px  !important;
  font-size: 14px !important;
  text-align: center !important;
  background-color: #4582ec !important;
  color: #fff !important;
  
}
.App {
    font-family: sans-serif;
    text-align: center;
  }
  .App button {
    padding: 13px 29px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    outline: none;
    background: rgb(64, 125, 194);
    color: white;
    cursor: pointer;
  }
  #portal .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
  }
  .container .modal {
    z-index: 1;
    width: 800px;
    height: 600px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    animation: animate 0.3s;
  }
  .ui.dimmer .ui.modal .ui.loader:before {
    border-color: rgba(0,0,0,.1);
  }
            
  .ui.dimmer .ui.modal .ui.loader:after {
    border-color: #767676 transparent transparent;
  }
  .modal-90w { max-width: 90% !important; }
  
  .hdg-label-popup {
    display: none;
    position: absolute
  }
  .modal-container {
    height: 600px;
  }

  .icon:hover~.hdg-label-popup {
    display: block
  }
  .modal button {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 8px 8px;
    background: rgb(108, 86, 189);
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
  }
  .wrapper {
    padding: 20px;
}
  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }